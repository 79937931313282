<template>
  <div>
    <div class="container-fluid planning-card-container">
      <b-card
        class="planning-card"
      >
        <b-row>
          <b-col
            :cols="innerWidth ? '3' : '12'"
          >
            <div
              class="pl-3 pb-3 d-flex align-items-center "
              :class="innerWidth ? '' : 'justify-content-center'"
            >
              <div class="btn-arrow">
                <i class="fa fa-chevron-left pointer" v-on:click="subscrtractDate()"></i>
              </div>
              <div
                class="w-10"
                :class="schedule.today ? 'topnav-title-blue-light-active' : 'topnav-title-blue-light'"
              >
                <b-input-group class="mt-1">
                  <b-input-group-prepend>
                      <span class="input-group-text background-light-blue-inputs">
                        <i class="fa fa-calendar text-green-dark form-control-feedback">
                        </i>
                      </span>
                  </b-input-group-prepend>
                  <b-form-input
                    class="background-light-blue-inputs date-input pointer"
                    size="sm"
                    :readonly="true"
                    :value="showDateText()"
                    @click="showCalendar = !showCalendar"
                  >
                  </b-form-input>
                  <b-input-group-append>
                      <span class="input-group-text right-append background-light-blue-inputs">
                        <i
                          class="icofont icofont-undo form-control-feedback"
                          :class="showDateText() === $t('general.actions.today') ? 'text-blue-disabled' : 'text-green-dark pointer'"
                          @click="resetDate()"
                        >
                        </i>
                      </span>
                  </b-input-group-append>
                </b-input-group>
                <d-calendar-modal
                  :show="showCalendar"
                  :hide="!showCalendar"
                  :identifier="'modal-calendar'"
                  @on:calendar-change="onChangedDate"
                />
              </div>
              <div class="btn-arrow">
                <i class="fa fa-chevron-right pointer" v-on:click="addDate()"></i>
              </div>
              <div v-if="false">
                <b-card-text>
                  <vs-slider
                    v-if="params"
                    v-model="params.size"
                    step="1"
                    min="1"
                    max="3"
                    ticks="true"
                  ></vs-slider>
                </b-card-text>
              </div>
            </div>
          </b-col>
          <b-col
            :cols="colWidthFilters"
            :align="innerWidth ? 'center' : 'right'"
            :class="innerWidth ? '' : 'margin-filters pr-0'"
          >
            <div>
              <activities-filters
                @on:activity-list-updated="onActivityListUpdate"
              />
            </div>
          </b-col>
          <b-col
            align="right"
            class="mt-1x p-0 m-0"
          >
            <export-pdf
              :btn="btnAttrs"
              :class="innerWidth ? '' : 'pt-1 pl-2 pl-3 pr-1 mt-1'"
              :date="$store.getters['planning/getDate']"
              target="formulas"
            />
          </b-col>
          <b-col
            :class="innerWidth ? '' : 'p-0'"
            :align="innerWidth ? 'right' : 'left'"
          >
              <span
                class="ml-2 d-btn btn d-btn-default font-text-title d-flex align-items-center param-width"
                :class="innerWidth ? '' : 'pt-2 pb-2 pl-3 pr-1 mr-3 mt-1'"
                v-on:click="showParams = true"
              >
                <i
                  class="fa fa-cog " :class="innerWidth ? 'mr-1 mb-1' : ''"
                ></i>
                <p v-if="innerWidth">{{ $t('planning_params.title') }}</p>
              </span>
          </b-col>
        </b-row>
        <schedule
          v-if="!isBusy"
          :schedule="schedule"
          :params="params"
          :hours="hours"
          :date="selectedDate"
          :reload-playground-list="reload"
          :selected-activities="selectedActivities"
          :busy-bookings="busyBookings"
          @on:schedule-load-succeed="onScheduleLoaded"
          @on:schedule-reload="loadParams"
        />
        <div
          v-else
          class="d-flex align-items-center align-content-center justify-content-center"
          :style="maxHeight"
        >
          <div>
            <img class="d-flex ml-5 pl-5 justify-content-center" :src="srcLoader"/>
          </div>
        </div>
      </b-card>
    </div>
    <params-modal
      v-if="showParams"
      :params="params"
      @on:click-close="onParamsHide"
      @on:params:update="refreshParams"
    />
  </div>
</template>
<script>
import {getParams} from "@api/doinsport/services/club/club.api";
import ActivitiesFilters from "@views/planning/ActivitiesFilters";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {getPrices} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {hydrate} from "@/utils/form";
import ExportPdf from "@custom/ExportPdf";
import {disableBodyScrollY} from "@/utils/style";

export default {
  components: {
    ExportPdf,
    ActivitiesFilters,
    ParamsModal: () => import('@custom/planning/ParamsModal'),
    Schedule: () => import('@views/planning/schedule/Index'),
    DatePicker: () => import ('vue2-datepicker')
  },
  data: () => ({
    showParams: false,
    showPlanning: true,
    reload: false,
    isBusy: false,
    scheduleLoaded: false,
    schedule: {
      today: true,
      tomorrow: false,
    },
    busyBookings: {
      isBusy: false,
    },
    srcLoader: require('@/assets/planning-loader.svg'),
    selectedDate: null,
    calendarDate: null,
    showCalendar: false,
    rangeValue: 1,
    params: null,
    hours: [],
    queue: [],
    selectedActivities: ['all'],
    windowZoom: false
  }),
  watch: {
    selectedDate: function () {
      const now = this.$moment();
      if (now.format('YYYMMDDD') === this.selectedDate.format('YYYYMMDD')) {
        this.selectedDate = now;
      }
      this.$store.dispatch('planning/setDate', this.selectedDate.format('YYYY-MM-DD'));
    }
  },
  methods: {
    onParamsHide() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.showParams = false;
        }, 50)
      });
    },
    onScheduleLoaded() {
      this.scheduleLoaded = true;
    },
    onActivityListUpdate(list) {
      this.selectedActivities = list;
      this.reload = !this.reload;
    },
    getDateText() {
      return this.selectedDate.format('LL').replace(' 00:00', '');
    },
    subscrtractDate() {
      this.selectedDate.subtract(1, 'days');
      this.onChangedDate(this.selectedDate, false);
    },
    addDate() {
      this.selectedDate.add(1, 'days');
      this.onChangedDate(this.selectedDate, false);
    },
    showDateText() {
      const now = this.$moment.utc();
      const tomorow = now.clone();

      tomorow.add(1, 'days');

      if (this.selectedDate.format('YYYYMMDD') === now.format('YYYYMMDD')) {
        return this.$t('general.actions.today');
      } else if (this.selectedDate.format('YYYYMMDD') === tomorow.format('YYYYMMDD')) {
        return this.$t('general.actions.tomorrow');
      } else {
        return this.selectedDate.format('DD/MM/YYYY');
      }
    },
    onChangedDate(newDate, change = true) {
      this.selectedDate = this.$moment(newDate);
      this.$store.dispatch('planning/setDate', this.selectedDate.format('YYYY-MM-DD'));
      if (change) {
        this.showCalendar = !this.showCalendar;
      }
      this.loadParams();
    },
    loadParams(date) {
      let dateString = this.selectedDate.format('YYYY-MM-DD');

      if (!this.isBusy) {
        this.isBusy = true;

        getParams(dateString)
          .then(resp => {
            if (resp) {
              this.params = resp.data;

              const startTime = this.$moment.utc(dateString + ' ' + this.params.timetable.startAt).tz(this.$store.getters["auth/currentClub"].timezone, true).minute(0);
              const endTime = this.$moment.utc(dateString + ' ' + this.params.timetable.endAt).tz(this.$store.getters["auth/currentClub"].timezone, true);
              if (endTime < startTime) {
                endTime.add(1, 'day');
              }
              endTime.minute(0).add(1, 'hours')
              this.hours = [];
              while (startTime <= endTime) {
                const temp = startTime.clone();
                this.hours.push(temp);
                startTime.add(1, 'hours');
              }
            }
          })
          .catch(err => {
            this.params = {
              bookingCategories: [],
              colors: {
                booking_from_app: "#0000FF",
                booking_from_club: "#FF0000",
                booking_from_web: "#0000FF",
                recurring_booking: "#FF9B00",
                lesson: "#EA4400",
                match: "#00A6EA",
                playground_closure: "#D3D3D3",
              },
              id: "f71b9daf-1669-48b6-997e-2748028a3e97",
              orientation: "vertical",
              size: 1,
              updatedAt: "2021-01-18T13:31:15+00:00",
              timetable: {
                startAt: '11:00',
                endAt: '23:00',
              }
            };
            const startTime = this.$moment.utc(dateString + ' ' + this.params.timetable.startAt).tz(this.$store.getters["auth/currentClub"].timezone).minute(0);
            const endTime = this.$moment.utc(dateString + ' ' + this.params.timetable.endAt).tz(this.$store.getters["auth/currentClub"].timezone).minute(0).add(1, 'hours');
            while (startTime < endTime) {
              const temp = startTime.clone();
              this.hours.push(temp);
              startTime.add(1, 'hours');
            }
          })
          .finally(() => {
            this.isBusy = false;

            if (this.queue.length > 0) {
              this.loadParams();
              this.queue = [];
            }
          })
        ;
      } else {
        this.queue.push(dateString)
      }
    },
    refreshParams() {
      let dateString = this.selectedDate.format('YYYY-MM-DD');

      getParams(dateString)
        .then(resp => {
          if (resp) {
            hydrate(this.params, resp.data);
          }
        })
      ;
    },
    differRequest(date) {
      this.queue.push({
        date: date,
        timeout: this.queue.length,
      });

      if (this.queue.length === 1) {
        this.onRequestDiffered();
      }
    },
    onRequestDiffered() {
      if (this.queue.length > 0) {
        let payload = this.queue[0];
        this.loadParams(payload.date);

        setTimeout(() => {
          this.queue.shift();
          this.differRequest();
        }, payload.timeout);
      }
    },
    resetDate() {
      if (this.showDateText() !== this.$t('general.actions.today')) {
        this.selectedDate = this.$moment.utc().tz(this.$store.getters["auth/currentClub"].timezone);
        this.$store.dispatch('planning/setDate', this.selectedDate.format('YYYY-MM-DD'));
      }
    },
    handleResize() {
      this.windowZoom = window.innerWidth < 1600;
    }
  },
  created() {
    this.selectedActivities = this.$store.getters["planning/getActivities"];
    const dateState = this.$store.getters["planning/getDate"];
    if (dateState === '' || !isNotUndefinedAndNotNull(dateState)) {
      this.selectedDate = this.$moment.utc().tz(this.$store.getters["auth/currentClub"].timezone);
      this.$store.dispatch('planning/setDate', this.selectedDate.format('YYYY-MM-DD'));
    } else {
      this.selectedDate = this.$moment(dateState);
    }
    this.calendarDate = this.$moment.utc().tz(this.$store.getters["auth/currentClub"].timezone);
    this.loadParams();
  },
  computed: {
    btnAttrs() {
      return {
        label: this.$store.getters['layout/getInnerWidth'] >= 991 ? 'components.custom.export-csv.export-all-formula-bookings' : '',
        icon: 'fa fa-external-link mr-2 mt-1',
        class: 'd-btn btn d-btn-default font-text-title',
      }
    },
    colWidthFilters() {
      if (this.$store.getters['layout/getInnerWidth'] < 400) {
        return '9';
      } else if (this.$store.getters['layout/getInnerWidth'] >= 400 && this.$store.getters['layout/getInnerWidth'] <= 700) {
        return '7';
      } else if (this.innerWidth && this.$store.getters['layout/getInnerWidth'] < 900) {
        return '5';
      } else {
        return '6';
      }
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    },
    maxHeight() {
      return 'height:' + window.top.outerHeight + 'px';
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  }
};
</script>
<style lang="scss" scoped>
@import "@lazy/planning/_planning-index.scss";

.mt-1x {
  margin-top: 1px;
}
</style>
