<template>
  <div class="pt-1">
    <div class="mb-3">
      <i
        class="d-icon-gray fa fa-chevron-left mr-2"
        @click="previous"
      />
      <d-button
        :class="isSelected('all') ? 'd-btn-primary' : 'd-btn-default'"
        class="d-btn btn font-text-title mr-2"
        text="general.actions.all"
        @on:button-click="selectUnselectActivity('all')"
      />
      <template v-for="(activity, key) of activities">
        <d-button
          v-if="(parseInt(key) < index) && (index - parseInt(key) < activityNumber)"
          :key="'activity' + key"
          :text="activity.name"
          :translated-text="false"
          :class="isSelected(activity['@id']) ? 'd-btn-primary' : 'd-btn-default'"
          class="d-btn btn  font-text-title mr-2"
          @on:button-click="selectUnselectActivity(activity['@id'])"
        />
      </template>
      <i
        class="d-icon-gray fa fa-chevron-right"
        @click="next"
      >
      </i>
    </div>
  </div>
</template>
<script>
import {getCustomActivities} from "@api/doinsport/services/activity/activity.api";

export default {
  data: () => ({
    nb: 3,
    index: 3,
    page: 1,
    activities: [],
  }),
  computed: {
    activityNumber() {
      if (this.$store.getters['layout/getInnerWidth'] <= 1050) {
        return 2;
      } else {
        return 4;
      }
    }
  },
  methods: {
    next() {
      if (this.index < this.activities.length) {
        this.index++;
      }
    },
    previous() {
      if (this.index > 3) {
        this.index--;
      }
    },
    isSelected(selected) {
      const selectionList = this.$store.getters["planning/getActivities"];
      const findSelectedActivity = selectionList.find(el => el === selected);

      if ("undefined" === typeof findSelectedActivity) {
        return false;
      } else {
        return true;
      }
    },
    selectUnselectActivity(selected) {
      const selectionList = this.$store.getters["planning/getActivities"];

      if (selected === 'all') {
        this.$store.commit('planning/setActivities', ['all']);
      } else {
        const findAll = selectionList.find(el => el === 'all');
        if ('undefined' !== typeof findAll) {
          this.$store.commit('planning/removeActivity', findAll);
        }
        const findSelectedActivity = selectionList.find(el => el === selected);

        if ("undefined" === typeof findSelectedActivity) {
          this.$store.commit('planning/addActivity', selected);
        } else {
          this.$store.commit('planning/removeActivity', findSelectedActivity);
        }
      }
      if (selectionList.length === 0) {
        this.$store.commit('planning/setActivities', ['all']);
      }

      this.$emit('on:activity-list-updated', this.$store.getters["planning/getActivities"]);
    },
    loadActivities() {
      getCustomActivities(500, this.page, '')
        .then((response) => {
          this.activities = [];
          const selectionList = this.$store.getters["planning/getActivities"];

          for (const activity of response.data['hydra:member']) {
            const findSelectedActivity = selectionList.find(el => el === activity['@id']);
            activity.selected = ("undefined" === typeof findSelectedActivity);

            this.activities.push(activity);
          }
        })
      ;
    }
  },
  created() {
    this.loadActivities();
  }
}
</script>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateY(10px);
  opacity: 0;
}
</style>
